export default class Module {
    moduleName = "";
    moduleDuration = "";
    moduleStatus = "";
    subModules: Module[] = [];

    constructor(moduleName: string, moduleDuration: string, subModules: Module[] = [], status: string = "not-started") {
        this.moduleName = moduleName;
        this.moduleDuration = moduleDuration;
        this.subModules = subModules;
        this.moduleStatus = status
    }
}