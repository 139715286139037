import "./index.css"

const Footer = () => {

    return (
        <footer>
            <p>© 2024 Micro Cursos. Todos os direitos reservados.</p>
        </footer>
    )
}

export default Footer