import Module from "../../models/module";
import CourseModule from "../course-module";
import MediaPlayer from "../mediaplayer";
import "./index.css"
import { useState } from "react"


const CourseContent = () => {
    const [modules, setModules] = useState([
        new Module("Introdução ao C#", "2h 30m", [
            { subModules: [], moduleName: "Conceitos Básicos", moduleDuration: "30m", moduleStatus: "not-started" },
            { subModules: [], moduleName: "Estruturas de Controle", moduleDuration: "45m", moduleStatus: "in-progress" },
            { subModules: [], moduleName: "Classes e Objetos", moduleDuration: "1h 15m", moduleStatus: "completed" }
        ]),
        new Module("Programação Avançada", "3h", [
            { subModules: [], moduleName: "Manipulação de Memória", moduleDuration: "1h", moduleStatus: "not-started" },
            { subModules: [], moduleName: "Threads e Paralelismo", moduleDuration: "1h", moduleStatus: "not-started" },
            { subModules: [], moduleName: "Programação Assíncrona", moduleDuration: "1h", moduleStatus: "not-started" }
        ])
    ]);
    return (
        <section className="curso-detalhes">
            <div className="curso-layout">
                <aside className="curso-modulos">
                    <h3>Módulos do Curso</h3>
                    {modules.map((module, index) => (
                        <CourseModule module={module} key={index} />
                    ))}
                </aside>

                <MediaPlayer videoSrc={require("./../../assets/Teste abstração Value Object.mp4")} />
            </div>
        </section>
    );
}

export default CourseContent