import Module from "../../models/module"
import React, { useState } from 'react';



interface Payload {
    module: Module
}


const CourseModule = (payload: Payload) => {
    const [isActive, setIsActive] = useState(false);
    const toggleAccordion = () => {
        setIsActive(!isActive);
    };

    return (
        <div className="modulo" >
            <button className={`accordion ${isActive ? 'active' : ''}`} onClick={toggleAccordion} >
                {`Módulo ${payload.module.moduleName} `}
                <span>({payload.module.moduleDuration})</span>
            </button>
            <div className="panel" style={{ maxHeight: isActive ? `${payload.module.subModules.length * 50}px` : '0' }}>
                <ul>
                    {payload.module.subModules.map((sub, subIndex) => (
                        <li key={subIndex}>
                            <span className={`status-icon ${sub.moduleStatus}`}></span>
                            {`${sub.moduleName} (${sub.moduleDuration})`}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
export default CourseModule