
import { Link } from "react-router-dom"
import CSharLogo from "./../../assets/csharp.svg"
import GolfLogo from "./../../assets/gof.jpg"
import microsservicesLogo from "./../../assets/microsservices.png"

import "./index.css"


const OurCurses = () => {

    return (
        <section id="cursos" className="cursos">
            <h2>Nossos Cursos</h2>
            <div className="cursos-container">
                <div className="curso-card">
                    <img src={CSharLogo} alt="Curso de C# Avançado" height="300" width="300" />
                    <h3>Curso de C# Avançado</h3>
                    <p>Aprenda C# em profundidade com os melhores tutores.</p>
                    <Link className="link-detalhes-btn" to={"curso/17"}>Ver Detalhes</Link>
                </div>
                <div className="curso-card">
                    <img src={GolfLogo} alt="Microserviços com .NET" height="300" width="300" />
                    <h3>Microserviços com .NET</h3>
                    <p>Domine a arquitetura de microserviços com .NET Core.</p>
                    <Link to={""} className="detalhes-btn link-btn" >Ver Detalhes</Link>
                </div>
                <div className="curso-card">
                    <img src={microsservicesLogo} alt="Design Patterns na Prática" height="300" width="300" />
                    <h3>Design Patterns na Prática</h3>
                    <p>Implemente os principais padrões de projeto no dia a dia.</p>
                    <Link to={""} className="link-btn detalhes-btn " >Ver Detalhes</Link>
                </div>
            </div>
        </section>
    )
}
export default OurCurses