import CourseContent from "../../components/course-content"
import Footer from "../../components/footer"
import NavBar from "../../components/navbar"



const CoursePage = () => {

    return (
        <div>
            <NavBar IsCoursePage={true} />
            <CourseContent />
            <Footer />

        </div>
    )
}

export default CoursePage