


import NavBar from "./../../components/navbar"
import LoginContent from "./../../components/login-content"
import Footer from "../../components/footer"


const LoginPage = () => {


    return (
        <div>
            <NavBar LoginMode={false} />
            <LoginContent />
            <Footer />
        </div>)
}


export default LoginPage