import React, { useRef, useState } from 'react';
import './index.css';

const MediaPlayer: React.FC<{ videoSrc: string }> = ({ videoSrc }) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [controlsVisible, setControlsVisible] = useState(true);
    const [language, setLanguage] = useState('pt');
    const [quality, setQuality] = useState('720p');

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            setCurrentTime(videoRef.current.currentTime);
            setDuration(videoRef.current.duration);
        }
    };

    const handleSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (videoRef.current) {
            videoRef.current.currentTime = Number(event.target.value);
        }
    };

    const handlePlaybackRateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (videoRef.current) {
            const rate = Number(event.target.value);
            videoRef.current.playbackRate = rate;
            setPlaybackRate(rate);
        }
    };

    const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (videoRef.current) {
            const vol = Number(event.target.value);
            videoRef.current.volume = vol;
            setVolume(vol);
        }
    };

    const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setLanguage(event.target.value);
    };

    const handleQualityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setQuality(event.target.value);
    };

    return (
        <div className="curso-video" onMouseEnter={() => setControlsVisible(true)} onMouseLeave={() => setControlsVisible(false)}>
            <h3>Video Aula</h3>
            <div className="video-container">
                <video
                    ref={videoRef}
                    className="video"
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleTimeUpdate}
                >
                    <source src={videoSrc} type="video/mp4" />
                    Seu navegador não suporta o player de vídeo.
                </video>
                {controlsVisible && (
                    <div className="controls">
                        <button className="play-pause" onClick={handlePlayPause}>
                            {isPlaying ? '⏸️' : '▶️'}
                        </button>
                        <input
                            type="range"
                            min={0}
                            max={duration}
                            value={currentTime}
                            onChange={handleSeek}
                            className="progress-bar"
                        />
                        <span className="time">
                            {Math.floor(currentTime)} / {Math.floor(duration)} s
                        </span>
                        <input
                            type="range"
                            min={0}
                            max={1}
                            step={0.1}
                            value={volume}
                            onChange={handleVolumeChange}
                            className="volume-bar"
                        />
                        <select value={playbackRate} onChange={handlePlaybackRateChange} className="playback-rate">
                            <option value={1}>1x</option>
                            <option value={1.5}>1.5x</option>
                            <option value={2}>2x</option>
                        </select>
                        <select value={language} onChange={handleLanguageChange} className="language">
                            <option value="pt">Português</option>
                            <option value="en">English</option>
                        </select>
                        <select value={quality} onChange={handleQualityChange} className="quality">
                            <option value="720p">720p</option>
                            <option value="1080p">1080p</option>
                        </select>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MediaPlayer;
