import React from 'react';
import { BrowserRouter, Route, Routes as R } from 'react-router-dom';

import Home from "./../modules/home/home_page"
import LoginPage from "./../modules/login/login_page"
import CoursePage from '../modules/course/course_page';

const Routes: React.FC = () => {
    return (
        <BrowserRouter>
            <R>
                <Route path="/" Component={Home} />
                <Route path="/login" Component={LoginPage} />
                <Route path="/login" Component={LoginPage} />
                <Route path="/curso/:id" Component={CoursePage} />
            </R >
        </BrowserRouter>
    );
};

export default Routes;