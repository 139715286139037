import React from "react"
import NavBar from "./../../components/navbar"
import HomeContent from "./../../components/home-content"
import OurCourses from "./../../components/our-courses"
import Footer from "../../components/footer"


const HomePage = () => (
    <div>
        <NavBar />
        <HomeContent />
        <OurCourses />
        <Footer />

    </div>
)
export default HomePage