import "./index.css"



const LoginContent = () => {

    return (
        <section className="login-container">
            <div className="login-box">
                <h2>Login</h2>
                <form>
                    <input type="email" placeholder="Email" required />
                    <input type="password" placeholder="Senha" required />
                    <button type="submit" className="login-btn">Entrar</button>
                </form>
                <p>Não tem uma conta? <a href="#">Registre-se</a></p>

            </div>
        </section>)
}
export default LoginContent