import "./index.css"
import { Link } from 'react-router-dom';

const NavBar = ({ LoginMode = true, IsCoursePage = false }) => {

    return (
        <header>
            <nav className="navbar">
                <div className="logo">
                    <a href="/">🎓 Micro Cursos</a>
                </div>
                {
                    LoginMode ? <div className="search-bar">
                        <input type="text" placeholder={IsCoursePage ? "Buscar cursos..." : "Buscar"} />
                    </div> : null
                }

                {
                    LoginMode ? <div className="user-menu">
                        <Link className="login-btn" to={"/login"} >Entrar</Link>
                    </div> : null
                }

            </nav></header >)
}
export default NavBar