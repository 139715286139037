import "./index.css"




const HomeContent = () => {


    return (
        <section className="hero">
            <div className="hero-content">
                <h2>Transforme seu Conhecimento em Poder</h2>
                <p>Aprenda de forma imersiva e alcance o próximo nível com nossos micro cursos.</p>
                <button className="cta-button">Explore</button>
            </div>
        </section>
    )
}
export default HomeContent